export const useAuthStore = defineStore('auth', () => {
  const supabase = useSupabaseClient()
  const user = useSupabaseUser()

  const state = reactive({
    loadingCurrentUser: false,
    currentUser: null,
    currentUserPromise: null,
  })

  // clear state when user signs out
  supabase.auth.onAuthStateChange(async (event) => {
    if (event === 'SIGNED_OUT') {
      state.currentUser = null
      state.loadingCurrentUser = null
      state.currentUserPromise = null
    }
  })

  async function queryUser() {
    if (!user.value)
      return null

    const { data, error } = await supabase
      .from('Users')
      .select(`
        name,
        requires_review,
        UserRoles (
          role
        )
      `)
      .eq('id', user.value.id)
      .limit(1)
      .single()

    if (error)
      throw createError(error)

    return {
      id: user.value.id,
      name: data.name,
      requires_review: data.requires_review,
      email: user.value.email,
      roles: data.UserRoles.map(r => r.role),
    }
  }

  async function getCurrentUser() {
    if (state.currentUser)
      return state.currentUser
    if (state.currentUserPromise)
      return await state.currentUserPromise

    state.loadingCurrentUser = true
    try {
      state.currentUserPromise = queryUser()
      state.currentUser = await state.currentUserPromise
    }
    finally {
      state.loadingCurrentUser = false
    }

    return state.currentUser
  }

  return {
    getCurrentUser,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
